
.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 2rem);
  justify-content: space-between;
  transition: margin-left .2s;
  border-radius: 12px;
  padding: .5rem 1.5rem;
  box-shadow: 0 3px 5px rgba(0,0,0,.02), 0 0 2px rgba(0,0,0,.05), 0 1px 4px rgba(0,0,0,.08);
  margin-left: 300px;
  background-color: #FFFFFF;
}

.layout-main {
  flex: 1 1 auto;
}


.layout-sidebar {
  position: fixed;
  width: 250px;
  height: calc(100vh - 2rem);
  z-index: 999;
  overflow-y: auto;
  left: 1rem;
  transition: transform .2s,left .2s;
  background-color: var(--surface-overlay);
  border-radius: 12px;
  padding: .5rem 1.5rem;
  box-shadow: 0 3px 5px rgba(0,0,0,.02), 0 0 2px rgba(0,0,0,.05), 0 1px 4px rgba(0,0,0,.08);
}

.layout-menu, .layout-sidebar {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.layout-config-button {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: var(--primary-color);
  color: var(--primary-color-text);
  text-align: center;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0,0,0,.15);
}

.flow-builder {
  width: calc(100% - 320px)
}

.p-card .p-card-content {
  padding: 0.1rem !important;
}

.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 12px;
}